<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="usersTableFields"
        :items="users"
        primary-key="uid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="flat-danger"
            @click="resetPasswordPopup(data.item.real_name, data.item.uid)"
          >
            <feather-icon
              icon="AlertOctagonIcon"
              class="mr-50"
            />
            <span class="align-middle">Reset</span>
          </b-button>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormInput, BButton,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  fetchMerchantUsers,
  resetMerchantUserPassword,
} = useMerchantsJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BButton,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      usersTableFields: [
        'nick_name',
        'real_name',
        'actions',
      ],
      filter: null,
      filterOn: ['real_name', 'nick_name', 'uid'],

      users: [],
      isMerchantUsersModalActive: false,
    }
  },
  created() {
    this.fetchUsers()
  },
  methods: {
    fetchUsers() {
      fetchMerchantUsers(
        this.merchantNo,
        users => {
          this.users = users
        },
        fail => {
          this.showAlert('warning', 'Fail!', `Fetch users fail with ${fail}`)
        },
      )
    },
    onFiltered(filteredItems) {
      this.totalApps = filteredItems.length
      this.currentPage = 1
    },
    resetPasswordPopup(realName, uid) {
      this.$swal({
        title: `Reset ${realName}'s password?`,
        text: 'reset to a random password, notify the return value.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.resetPassword(uid)
        }
      })
    },
    resetPassword(uid) {
      resetMerchantUserPassword(
        uid,
        pwd => {
          showAlert(this, 'success', 'Success!', `Reset user's password to [${pwd}]`)
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Reset user's password fail with ${fail}`)
        },
      )
    },
  },
}
</script>
